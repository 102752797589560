import React from 'react';
import { BsInstagram, BsDiscord, BsGoogle } from 'react-icons/bs';


const socialMedia = () => {
  return (
    <div className="app__social">
        
        
      <a href="https://www.instagram.com/educode.tutoring/" target="_blank" rel="noreferrer">
        <div>
            <BsInstagram />
        </div>
      </a>
      <a href="https://discord.gg/xjmtrwmWrc" target="_blank" rel="noreferrer">
        <div>
            <BsDiscord />
        </div>
      </a>
      <a href="https://classroom.google.com/c/NDAyMDQwMDY0Nzk0?cjc=jis4c6g/" target="_blank" rel="noreferrer">
        <div>
            <BsGoogle />
        </div>
      </a>
    </div>
  )
}

export default socialMedia