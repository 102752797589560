import React from 'react';

import { About, Footer, Header, Work } from './container';
import { Navbar } from './components';
import './App.scss';

const App = () => {
  return (
    <div className="app">
        <Navbar />
        <Header />  
        <Work />
        <About />
        <Footer />
    </div>
  );
}

//removed <Testimonial /> <Skills />

export default App;